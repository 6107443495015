import type {
  LcLotteryTeaserVariant,
  LcTeaserBackgroundPattern,
  LcTeaserBadgeVariant,
  LcTeaserHeroBadgeColor,
  LcTeaserHeroBadgeVariant,
} from '@lottocom/frontend-components'

import type {
  BadgeType,
  CampaignPayload,
  HeroHighlightStyle,
} from '~/@types/generated/backend/graphql-schema-types'

const CAMPAIGN_PLACEMENT: Record<HeroHighlightStyle, LcTeaserHeroBadgeVariant> =
  {
    BottomM: 'bottom-m',
    BottomXL: 'bottom-xl',
    TopM: 'top-m',
    TopXL: 'top-xl',
  }

const BADE_VARIANT: Record<BadgeType, LcTeaserBadgeVariant> = {
  Fire: 'fire',
  New: 'new',
  Pie: 'pie',
  PriceTag: 'price-tag',
  Star: 'star',
  Text: 'text',
}

export const useOnsiteMarketingTeaser = (
  campaign: Ref<Maybe<CampaignPayload> | undefined>,
  variant?: Ref<LcLotteryTeaserVariant | undefined>,
) => {
  const hypeStore = useHypeStore()

  const campaignTeaserVariant = computed(() => {
    switch (campaign.value?.__typename) {
      case 'CampaignBadgePayload':
        return 'badge'
      case 'CampaignHeroHighlightPayload':
        return 'hero'
      default:
        return undefined
    }
  })

  const lotteryTeaserVariant = computed(
    () => campaignTeaserVariant.value ?? variant?.value,
  )

  const badge = computed(() => {
    switch (campaign.value?.__typename) {
      case 'CampaignBadgePayload':
        return {
          infoHeadline: campaign.value.infoHeadline ?? '',
          infoText: campaign.value.infoText ?? '',
          title: campaign.value.title ?? '',
          variant: BADE_VARIANT[campaign.value.type],
        }
      case 'CampaignHeroHighlightPayload':
        return {
          color: campaign.value.badge.color as LcTeaserHeroBadgeColor,
          infoHeadline: campaign.value.badge.infoHeadline ?? '',
          infoText: campaign.value.badge.infoText ?? '',
          subtitle: campaign.value.badge.subtitle ?? '',
          title: campaign.value.badge.title ?? '',
          variant: CAMPAIGN_PLACEMENT[campaign.value?.badge.placement],
        }
      default:
        return undefined
    }
  })

  const backgroundPattern = computed(() =>
    campaign.value?.__typename === 'CampaignHeroHighlightPayload'
      ? (campaign.value?.pattern?.toLowerCase() as LcTeaserBackgroundPattern)
      : undefined,
  )

  const bonusCode = computed(() => {
    switch (campaign.value?.__typename) {
      case 'CampaignBadgePayload':
        return campaign.value.bonusCode
      case 'CampaignHeroHighlightPayload':
        return campaign.value.badge.bonusCode
      default:
        return undefined
    }
  })

  const addBonusCode = () => {
    if (!bonusCode.value) return
    hypeStore.setCoupon(bonusCode.value)
  }

  return {
    addBonusCode,
    backgroundPattern,
    badge,
    campaignTeaserVariant,
    lotteryTeaserVariant,
  }
}
